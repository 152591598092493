import FileService from "../services/file.service";

const initialState = {
  items: [],
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    getAll({ commit }, params) {
      console.log("getAll files");
      return FileService.getAll(params).then(
        (result) => {
          console.log("result", result);
          commit("getFilesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getFilesFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getFilesSuccess(state, result) {
      state.items = result;
    },
    getFilesFailure(state) {
      state.items = null;
    },
  },
};
