<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <FileExplorer path="/shippingdata/exportdata" />
    </div>
  </div>
</template>
<script>
import FileExplorer from "../../components/Widgets/FileExplorer.vue";

export default {
  components: {
    FileExplorer,
  },
  data() {
    return {
      name: null,
      id: null,
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style scoped></style>
