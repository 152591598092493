<template>
  <md-card>
    <md-card-header class="md-card-header-icon">
      <div class="card-icon">
        <span class="material-symbols-outlined">download</span>
      </div>
      <h4 class="title">{{ $t("layout.wms.fileExplorer") }}</h4>
    </md-card-header>
    <md-card-content>
      <!-- <md-field>
        <label>{{ $t("path") }}</label>
        <md-input v-model="path" disabled @input="debounceApplyFilters"></md-input>
      </md-field> -->
      <md-field md-clearable>
        <label>{{ $t("products.keywords") }}</label>
        <md-input v-model="search" @input="debounceApplyFilters"></md-input>
      </md-field>
      <md-table v-model="tableData" table-header-color="green" class="table-sticky" md-sort="createdAt" md-sort-order="desc" @sort="sortData">
        <md-table-empty-state md-label="No files found" :md-description="`No file found. Try a different search term.`"> </md-table-empty-state>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell :md-label="$t('suppliers.name')">
            <!-- <md-icon v-if="item.type === 'file'">description</md-icon>
             <md-icon v-else>folder</md-icon>  -->
            {{ item.name }}</md-table-cell
          >
          <md-table-cell :md-label="$t('fileExplorer.size')">{{ item.size | formatBytes }}</md-table-cell>
          <md-table-cell :md-label="$t('fileExplorer.createdAt')">{{ item.createdAt | formatDateTime }}</md-table-cell>
          <md-table-cell :md-label="$t('suppliers.actions')">
            <md-button class="md-icon-button" @click="download(item)" :disabled="isLoading"><md-icon>download</md-icon></md-button></md-table-cell
          >
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
import { debounce } from "lodash";
import fileService from "../../services/file.service";
export default {
  components: {},
  data() {
    return {
      path: "path/to/file",
      isLoading: false,
      items: [],
      currentPage: 1,
      pageSize: 50,
      orderBy: "createdAt",
      ascending: false,
      totalItems: 0,
      search: "",
    };
  },
  computed: {
    tableData() {
      console.log("this.$store.state.files", this.$store.state.files);
      return this.$store.state.files.items || [];
    },
    confirmDeleteMessage() {
      return this.$t("news.confirmDeleteMessage", {
        headline: this.post?.title,
      });
    },
  },
  methods: {
    debounceApplyFilters: debounce(function () {
      // this.applyFilters({});
      this.getData();
    }, 300),
    async download(item) {
      this.isLoading = true;
      const file = await fileService.downloadFile(item.name);
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item.name);
      document.body.appendChild(link);
      link.click();
      this.isLoading = false;
      this.$notify({
        timeout: 2500,
        message: this.$t("fileExplorer.downloadSuccess"),
        icon: "download",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
      });
    },
    sortData(args) {
      console.log("args", args);
      this.orderBy = orderBy;
      this.ascending = ascending;
      this.getData();
    },
    getData(params) {
      const _params = {
        search: this.search,
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        orderBy: this.orderBy,
        ascending: this.ascending,
      };
      this.$store.dispatch("files/getAll", { ..._params, ...params });
    },
  },
  created() {
    Promise.all([this.getData()]).finally((_) => {
      this.isLoading = false;
    });
  },
};
</script>

<style scoped></style>
