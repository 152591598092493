import axiosFactory from "./axios.factory";

class BaseService {
  entity = "";
  constructor(_entity) {
    console.log("entity", _entity);
    this.entity = _entity || "";
  }
  async getAll(params = {}) {
    const result = await axiosFactory().get(`/${this.entity}`, { params });
    return result.data.data;
  }
  async create(opts) {
    const result = await axiosFactory().post(`/${this.entity}`, opts);
    return result.data.data;
  }

  async update(opts) {
    const result = await axiosFactory().put(`/${this.entity}/${opts.id}`, opts);
    return result.data.data;
  }

  async delete(id) {
    const result = await axiosFactory().delete(`/${this.entity}/${id}`);
    return result.data.data;
  }
}

export default BaseService;
