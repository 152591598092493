//const baseURLApi = "http://localhost:5206";
//const baseURLApi = "https://localhost:7115";
const baseURLApi = "https://api.cst-occ.de";
//const baseURLApi = "https://stagingapi.cst-occ.de";

export default {
  baseURLApi,
  isBackend: process.env.VUE_APP_BACKEND,
  api: {
    url: `${baseURLApi}/api`,
  },
  locale: {
    currency: "EUR",
    language: "de",
    numberStyle: "de-DE",
    digit: 4,
  },
};
