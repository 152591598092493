import axiosFactory from "./axios.factory";
import BaseService from "./base.service";

/**
 * News Service
 * @class PostService
 * @extends BaseService
 */
class FileService extends BaseService {
  async downloadFile(fileName) {
    return axiosFactory()
      .get(`${this.entity}/download`, {
        responseType: "blob",
        params: { name: fileName },
      })
      .then((res) => res.data);
  }
}

export default new FileService("files/warehouses");
